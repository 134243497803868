import React from "react"
import { Link } from "gatsby"
import Seo from "src/utils/Seo"
import Fade from "react-reveal/Fade"
import Breadcrumbs from "src/utils/Breadcrumbs"
import { useCookies } from "react-cookie"
import { useForm } from "react-hook-form"
import {
  useNetlifyForm,
  NetlifyFormProvider,
  NetlifyFormComponent,
  Honeypot,
} from "react-netlify-forms"
import { navigate } from "gatsby"

const ArchiveBrochureDownloads = props => {

  const DownloadsForm = props => {
    const { register, formState: { errors }, handleSubmit } = useForm()
    const [cookies] = useCookies()
    const gclid = cookies.gclid  

    const netlify = useNetlifyForm({
      name: "brochure-download",
      action: "/brochure-downloads/thank-you",
      honeypotName: "bot-field",
      onSuccess: (response, context) => {
        console.log("Successfully sent form data to Netlify Server")
        window.dataLayer.push({event: 'form-submission', formName: 'brochure-downloads'});
        navigate("/brochure-downloads/thank-you")
      },
    })

    const onSubmit = data => {
      netlify.handleSubmit(null, data)
    }

    return (
      <Fade>
        <div className="container pt-0">
          <div className="flex flex-row relative flex-wrap lg:flex-nowrap xl:mb-0 lg:mb-24">
            <div className="w-full lg:w-7/12 hidden lg:block">
              <img
                className="lg:w-100 w-full"
                alt="Brochure form background"
                src={
                  "https://images.prismic.io/deuren/d1ea47e2-20f6-4776-91cb-5b6b8698b254_brochure-downloads+%281%29.jpg?auto=compress,format"
                }
              />
            </div>

            <NetlifyFormProvider {...netlify}>
              <NetlifyFormComponent
                id="brochure-download-form"
                className="bg-white lg:mt-12 lg:p-16 p-6 w-full lg:w-3/5 lg:absolute lg:right-0"
                onSubmit={handleSubmit(onSubmit)}
              >
                <Honeypot />
                <div className="flex flex-row space-x-6 mb-6">
                  <div className="w-full">
                    <label className="forminputlabel" htmlFor="newsletter">
                      SELECT WHICH BROCHURES YOU’D LIKE TO DOWNLOAD{" "}
                      <span className="text-gold">*</span>
                    </label>
                    <p className="formbodytext mb-6">
                      You can select more than one door type if you wish.
                    </p>
                    <div className="flex lg:flex-row flex-col lg:space-y-0 space-y-4">
                      <div className="lg:w-1/3">
                        <label className="flex justify-start items-start gap-2">
                          <input
                            type="checkbox"
                            className="opacity-0 absolute"
                            name="door_options"
                            value="Internal doors"
                            {...register("door_options", { required: true })}
                          />
                          <i className="far fa-circle"></i>
                          <i className="fas fa-check-circle"></i>
                          <div className="formcheckboxlabel" htmlFor="door_options">
                            Internal doors
                          </div>
                        </label>
                      </div>
                      <div className="lg:w-1/3">
                        <label className="flex justify-start items-start gap-2">
                          <input
                            type="checkbox"
                            className="opacity-0 absolute"
                            name="door_options"
                            value="Front doors"
                            {...register("door_options",{ required: true })}
                          />
                          <i className="far fa-circle"></i>
                          <i className="fas fa-check-circle"></i>
                          <div className="formcheckboxlabel" htmlFor="door_options">
                            Front doors
                          </div>
                        </label>
                      </div>
                      <div className="lg:w-1/3">
                        <label className="flex justify-start items-start gap-2">
                          <input
                            type="checkbox"
                            className="opacity-0 absolute"
                            name="door_options"
                            value="Garage doors"
                            {...register("door_options",{ required: true })}
                          />
                          <i className="far fa-circle"></i>
                          <i className="fas fa-check-circle"></i>
                          <div className="formcheckboxlabel" htmlFor="door_options">
                            Garage doors
                          </div>
                        </label>
                      </div>
                    </div>
                    <span className="formerror">
                      {errors.door_options &&
                        "Please tick at least one of the above"}
                    </span>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="lg:w-1/2">
                    <label htmlFor="first_name" className="forminputlabel">
                      First name *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="First name"
                      name="first_name"
                      {...register("first_name",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.first_name && "First name is required"}
                    </span>
                  </div>
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="last_name">
                      Last name *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="Last name"
                      name="last_name"
                      {...register("last_name",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.last_name && "Last name is required"}
                    </span>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="email_address">
                      Email address *
                    </label>
                    <input
                      className="forminput"
                      type="email"
                      placeholder="Email address"
                      name="email_address"
                      {...register("email_address",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.email_address && "Email address is required"}
                    </span>
                  </div>
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="phone_number">
                      Phone number *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="Phone number"
                      name="phone_number"
                      {...register("phone_number",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.phone_number && "Phone number is required"}
                    </span>
                  </div>
                </div>
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="house_name">
                      House name/number *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="House name/number"
                      name="house_name"
                      {...register("house_name",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.house_name && "House name/number is required"}
                    </span>
                  </div>
                  <div className="lg:w-1/2">
                    <label className="forminputlabel" htmlFor="postcode">
                      Postcode *
                    </label>
                    <input
                      className="forminput"
                      type="text"
                      placeholder="Postcode"
                      name="postcode"
                      {...register("postcode",{ required: true })}
                    />
                    <span className="formerror">
                      {errors.postcode && "Phone number is required"}
                    </span>
                  </div>
                </div>
                {/* <FormJourneyStageInterest register={register} errors={errors}/> */}
                <div className="flex lg:flex-row flex-col lg:space-x-6 lg:space-y-0 space-y-6 mb-6">
                  <div className="w-full">
                    <label className="forminputlabel" htmlFor="newsletter">
                      We'd love to keep in touch
                    </label>
                    <p className="formbodytext mb-6">
                      We'd like to keep you up to date with the latest product
                      information and news from Deuren by email. We’ll always
                      treat your personal details with the utmost care and will
                      never sell them to other companies for marketing purposes.
                      All emails include an unsubscribe link. You may opt-out at
                      any time. See our Privacy Policy.
                    </p>
                    <label className="flex justify-start items-start gap-2">
                      <input
                        type="checkbox"
                        className="opacity-0 absolute"
                        name="newsletter"
                        {...register("newsletter")}
                      />
                      <i className="far fa-circle"></i>
                      <i className="far fa-check-circle"></i>
                      <div className="formcheckboxlabel" htmlFor="newsletter">
                        I agree to receive occasional emails containing the
                        latest product information and news from Deuren.
                      </div>
                    </label>
                    <span className="formerror">
                      {errors.newsletter &&
                        "Please tick the box above to submit this form"}
                    </span>
                  </div>
                </div>
                <div className="flex flex-row space-x-6 mb-6">
                  <div className="w-full">
                    <input
                      type="text"
                      className="hidden"
                      name="gclid"
                      value={gclid}
                      {...register("gclid")}
                    />
                    <input
                      className="btn cursor-pointer outline-none"
                      type="submit"
                      value="Submit"
                    />
                  </div>
                </div>
              </NetlifyFormComponent>
            </NetlifyFormProvider>
          </div>
        </div>
      </Fade>
    )
  }

  const crumbs = [
    { label: "Brochure Downloads", href: "/brochure-downloads", active: true },
  ]

  return (
    <article>
      <Seo title={"Brochure Downloads"} />
      <div className="py-5">
        <Breadcrumbs data={crumbs} theme="light" />
      </div>
      <section className={`bg-offwhite`}>
        <div className="container text-center">
          <Fade>
            <h2 className="font-display text-gold rfs:text-5xl">
              Brochure Downloads
            </h2>
            <div className="w-12 separator mt-5"></div>
            <p className="max-w-2xl mt-6 m-auto">
              Download one of our detailed brochures to find out more about our
              front, internal and garage door ranges. Featuring our most popular
              designs along with technical specifications, configuration
              information, our range of woods and finishes, and available
              accessories, you can pick your perfect new door from the comfort
              of your own home.
            </p>
          </Fade>
        </div>
      </section>
      <div>
        <DownloadsForm />
      </div>
      <section className="block-three-columns bg-verylightgrey">
        <div className="container grid grid-cols-1 lg:grid-cols-3 gap-16">
          <Fade>
            <Link className="group" title="Made in the UK" to="/contact/">
              <img
                src="https://images.prismic.io/deuren/c49a2d18-46d6-4c51-9453-1ad8452b480d_brochure-download-related-get-in-touch.jpg?auto=compress,format"
                className="transition duration-500 ease-in-out group-hover:opacity-70 inline mb-10"
                alt="Brochure download related get in touch"
              />
              <h3 className="font-display text-gold rfs:text-4xl">Get in Touch</h3>
              <div className="w-10 mt-3 separator"></div>
              <div className="mt-6 leading-normal">
                <p>
                  Whether it’s to book an appointment to visit us, get expert
                  advice or talk through your requirements, we’d love to hear
                  from you.
                </p>
              </div>
            </Link>
          </Fade>
          <Fade>
            <Link className="group" title="Made in the UK" to="/visit-the-factory/">
              <img
                src="https://images.prismic.io/deuren/51803698-4364-4c37-97e0-b9a0b4709cb1_brochure-download-related-visit-the-factory.jpg?auto=compress,format"
                className="transition duration-500 ease-in-out group-hover:opacity-70 inline mb-10"
                alt="Brochure download related visit the factory"
              />
              <h3 className="font-display text-gold rfs:text-4xl">
                Visit the Factory
              </h3>
              <div className="w-10 mt-3 separator"></div>
              <div className="mt-6 leading-normal">
                <p>
                  It’s true when they say seeing is believing. Get exclusive
                  access to our premises and see our expert engineers in action.
                </p>
              </div>
            </Link>
          </Fade>
          <Fade>
            <Link className="group" title="Made in the UK" to="/request-a-quote/">
              <img
                src="https://images.prismic.io/deuren/0b684aba-b6c3-40bb-bf50-775cc36576a7_brochure-download-related-request-a-quote.jpg?auto=compress,format"
                className="transition duration-500 ease-in-out group-hover:opacity-70 inline mb-10"
                alt="Brochure download related request a quote"
              />
              <h3 className="font-display text-gold rfs:text-4xl">
                Request a Quote
              </h3>
              <div className="w-10 mt-3 separator"></div>
              <div className="mt-6 leading-normal">
                <p>
                  From handcrafted interiors doors to precision made, striking
                  front doors, it all starts with us finding out your exact
                  requirements.
                </p>
              </div>
            </Link>
          </Fade>
        </div>
      </section>
    </article>
  )
}

export default ArchiveBrochureDownloads
